import React from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useBill} from '../../hooks/use-bill';
import '../../styles/plaid.css';

export default function BillPage({user}) {
  const navigate = useNavigate();
  const {org_id} = useParams();
  const {
    state: {connections},
  } = useBill();

  const {organizations} = connections[org_id] || {};

  return (
    <div className="page-container">
      <div className="flex-row justify-between">
        <table>
          <thead>
            <tr>
              <th>Organization</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {organizations?.length &&
              organizations.map(item => {
                const {orgName} = item;
                return (
                  <tr key={orgName}>
                    <td>{orgName}</td>
                    <td>{'Active'}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          margin: '32px',
        }}>
        <button
          className="disconnect"
          onClick={() => {
            navigate('/dashboard');
          }}>
          Done
        </button>
      </div>
    </div>
  );
}
