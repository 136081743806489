import {Alert} from '@mui/material';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import {
  createUserWithEmailAndPassword,
  getAuth,
  signInWithEmailAndPassword,
} from 'firebase/auth';
import {doc, setDoc} from 'firebase/firestore';
import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {db} from '../../api/firebase';
import FirestoreManager from '../../api/firebaseManager';
import AuthFormFooter from '../../components/Auth/AuthFormFooter';
import AuthLeft from '../../components/Auth/AuthLeft';
import CustomForm from '../../components/Forms/CustomForm';

function Signup() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  // const [fullName, setFullName] = useState('');
  // const [customerID, setCustomerID] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [processingSignup, setProcessingSignup] = useState();

  const processSignup = async () => {
    const auth = getAuth();
    setProcessingSignup(true);

    const response = await FirestoreManager.checkUserMapping(email);

    const {exists, name, customerID} = response;

    if (exists) {
      createUserWithEmailAndPassword(auth, email, password)
        .then(async userCredential => {
          const user = userCredential.user;

          try {
            await setDoc(doc(db, 'users', user.uid), {
              uid: user.uid,
              fullName: name,
              emailAddress: email,
              customerID,
              date: new Date(),
            });
            signInWithEmailAndPassword(auth, email, password)
              .then(async userCredential => {
                navigate('/dashboard');
              })
              .catch(error => {
                setError(`Error code ${error.code}: ${error.message}`);
              });
            setProcessingSignup(false);
          } catch (e) {
            setError(e);
            setProcessingSignup(false);
          }
        })
        .catch(error => {
          setError(`Error code ${error.code}: ${error.message}`);
          setProcessingSignup(false);
        });
    } else {
      setError(
        'Invalid Email. Please contact Forest Support at support@forestsystems.com.',
      );
      setProcessingSignup(false);
    }
  };

  return (
    <div>
      <div className="auth-style">
        <Container sx={{marginTop: '40px', marginBottom: '40px'}} maxWidth="xl">
          <CustomForm submitHandler={processSignup}>
            <Grid container spacing={2}>
              <Grid item lg={6} xs={12}>
                <AuthLeft subheading="To receive transactions in the Forest Enter application for your bank accounts, sign up and connect your bank accounts." />
              </Grid>
              <Grid item lg={6} xs={12}>
                <Box
                  className="custom-form auth-right-wrapper"
                  component="form"
                  sx={{
                    '& > :not(style)': {
                      width: '100%',
                      height: '600px',
                      backgroundColor: '#336a3e',
                    },
                  }}
                  noValidate
                  autoComplete="off">
                  <Grid container spacing={2}>
                    <>
                      <Grid item xs={12}>
                        <FormControl fullWidth></FormControl>
                      </Grid>
                      {/* <Grid item xs={12}>
                        <FormControl fullWidth>
                          <TextField
                            id="customerID"
                            label="Customer ID"
                            style={{background: 'white'}}
                            value={customerID}
                            onChange={evt => setCustomerID(evt.target.value)}
                            variant="filled"
                            placeholder="Customer ID"
                          />
                        </FormControl>
                      </Grid> */}
                      {/* <Grid item xs={12}>
                        <FormControl fullWidth>
                          <TextField
                            id="fullName"
                            label="Name"
                            style={{background: 'white'}}
                            value={fullName}
                            onChange={evt => setFullName(evt.target.value)}
                            variant="filled"
                            placeholder="Name"
                          />
                        </FormControl>
                      </Grid> */}
                      <Grid item xs={12}>
                        <FormControl fullWidth>
                          <TextField
                            id="email"
                            label="Email"
                            value={email}
                            style={{background: 'white'}}
                            onChange={evt => setEmail(evt.target.value)}
                            variant="filled"
                            placeholder="Email"
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl fullWidth>
                          <TextField
                            id="password"
                            label="Password"
                            value={password}
                            style={{background: 'white'}}
                            onChange={evt => setPassword(evt.target.value)}
                            variant="filled"
                            type="password"
                            autoComplete="current-password"
                            placeholder="Confirm Password"
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl fullWidth>
                          <TextField
                            id="confirmPassword"
                            label="Confirm Password"
                            style={{background: 'white'}}
                            value={confirmPassword}
                            type="password"
                            autoComplete="current-password"
                            onChange={evt =>
                              setConfirmPassword(evt.target.value)
                            }
                            variant="filled"
                            placeholder="Confirm Password"
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <AuthFormFooter
                          processClick={processSignup}
                          disabled={processingSignup}
                          whiteText="Already have an account?"
                          buttonText={
                            processingSignup ? 'Loading...' : 'Sign up'
                          }
                          blueText="Log in"
                          blueTextUrl="/login"
                        />
                        <div style={{marginTop: '187px'}}></div>
                      </Grid>
                    </>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </CustomForm>
        </Container>
        <Collapse in={error !== ''}>
          <Alert className="alert" severity="error">
            {error}
          </Alert>
        </Collapse>
      </div>
    </div>
  );
}

export default Signup;
