import {getAuth} from 'firebase/auth';
import React, {useEffect, useState} from 'react';
import {
  Navigate,
  Outlet,
  Route,
  BrowserRouter as Router,
  Routes,
} from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
import {Provider as AuthProvider} from './contexts/AuthContext';
import {Provider as BillProvider} from './contexts/BillContext';
import {Provider as PlaidProvider} from './contexts/PlaidContext';
import ClientAccounts from './routes/Admin/ClientAccounts';
import ClientDashboard from './routes/Admin/ClientDashboard';
import UserAccounts from './routes/Admin/UserAccounts';
import UserDashboard from './routes/Admin/UserDashboard';
import Login from './routes/Auth/Login';
import Signup from './routes/Auth/Signup';
import AccountPage from './routes/Plaid/AccountPage';
import BillPage from './routes/Plaid/BillPage';
import Dashboard from './routes/Plaid/Dashboard';

const Content = () => {
  const [hasFetchedUserAuthState, setHasFetchedUserAuthState] = useState(false);
  const [userAuthState, setUserAuthState] = useState();

  const RequireAuth = ({children, redirectTo}) => {
    if (userAuthState === undefined) {
      return <></>;
    }
    return userAuthState ? (
      children || <Outlet />
    ) : (
      <Navigate to={redirectTo} />
    );
  };

  // TODO: will this work how we want or will it only set a listener one time
  // thats destroyed once they navgite from the page
  useEffect(() => {
    if (!hasFetchedUserAuthState) {
      const auth = getAuth();
      auth.onAuthStateChanged(function (user) {
        if (user) {
          setHasFetchedUserAuthState(true);
          setUserAuthState(user);
          // User is signed in.
        } else {
          // No user is signed in.
          setHasFetchedUserAuthState(true);
          setUserAuthState(false);
        }
      });
    }
  });

  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Navbar user={userAuthState} />}>
            <Route path="/" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/login" element={<Login />} />
            <Route path="/" element={<RequireAuth redirectTo="/login" />}>
              <Route
                path="/admin/user/dashboard"
                element={<UserDashboard user={userAuthState} />}
              />
              <Route
                path="/admin/user/accounts/:id"
                element={<UserAccounts user={userAuthState} />}
              />
              <Route
                path="/admin/client/dashboard"
                element={<ClientDashboard user={userAuthState} />}
              />
              <Route
                path="/admin/client/accounts/:id"
                element={<ClientAccounts user={userAuthState} />}
              />
              <Route
                path="/dashboard"
                element={<Dashboard user={userAuthState} />}
              />
              <Route
                path="/accounts/:item_id"
                element={<AccountPage user={userAuthState} />}
              />
              <Route
                path="/connections/:org_id"
                element={<BillPage user={userAuthState} />}
              />
            </Route>
          </Route>
        </Routes>
      </Router>
    </>
  );
};

const App = () => {
  return (
    <BillProvider>
      <PlaidProvider>
        <AuthProvider>
          <Content />
        </AuthProvider>
      </PlaidProvider>
    </BillProvider>
  );
};

export default App;
