import FirestoreManager from '../api/firebaseManager';
import createDataContext from './createDataContext';

const authReducer = (state, action) => {
  const {uid, update} = action.payload;
  switch (action.type) {
    case 'default':
      return {...state, ...action.payload};
    case 'setClient':
      const ids = state.client_ids;
      if (!ids.includes(uid)) {
        ids.unshift(uid);
      }
      return {
        ...state,
        client_ids: ids,
        clients: {
          ...state.clients,
          [uid]: {...(state?.clients?.[uid] ?? {}), ...update},
        },
      };
    case 'deleteClient':
      const removed_ids = [...state.client_ids].filter(id => id !== uid);
      return {
        ...state,
        client_ids: removed_ids,
        clients: {...state.clients, [uid]: null},
      };
    case 'deleteUser':
      const removed_users = [...state.user_ids].filter(id => id !== uid);
      return {
        ...state,
        user_ids: removed_users,
        users: {...state.users, [uid]: null},
      };
    default:
      return state;
  }
};

const getProfile = dispatch => async uid => {
  try {
    const profile = await FirestoreManager.getUserObject(uid);
    const {customerID} = profile;
    let client = {};
    if (customerID) {
      client = await FirestoreManager.getClient(customerID);
    }
    dispatch({type: 'default', payload: {profile, client}});
    return profile;
  } catch (err) {
    console.log(err);
  }
};

const setUserDoc = dispatch => async (uid, obj) => {
  try {
    await FirestoreManager.setUserObject(uid, obj);
    const profile = await FirestoreManager.getUserObject(uid);
    dispatch({type: 'default', payload: {profile}});
  } catch (err) {
    console.log(err);
  }
};

const getUsers = dispatch => async () => {
  try {
    const users_list = await FirestoreManager.getAllUsers();
    const users = {};

    const user_ids = users_list.map(user => {
      const {uid} = user;
      users[uid] = user;
      return uid;
    });
    dispatch({type: 'default', payload: {users, user_ids, users_loaded: true}});
    return users;
  } catch (err) {
    console.log(err);
  }
};

const getClients = dispatch => async () => {
  try {
    const clients_list = await FirestoreManager.getClients();

    const clients = {};

    const client_ids = clients_list.map(user => {
      const {id} = user;
      clients[id] = user;
      return id;
    });

    dispatch({
      type: 'default',
      payload: {clients, client_ids, clients_loaded: true},
    });
  } catch (err) {
    console.log(err);
  }
};

const updateClient = dispatch => async (uid, update) => {
  try {
    await FirestoreManager.setClient(uid, update);
    dispatch({type: 'setClient', payload: {uid, update}});
  } catch (err) {
    console.log(err);
  }
};

const deleteClient = dispatch => async uid => {
  try {
    await FirestoreManager.removeClient(uid);
    dispatch({type: 'deleteClient', payload: {uid}});
  } catch (err) {
    console.log(err);
  }
};

const deleteUser = dispatch => async uid => {
  try {
    await FirestoreManager.removeUserObject(uid);
    dispatch({type: 'deleteUser', payload: {uid}});
  } catch (err) {
    console.log(err);
  }
};

const defaultValues = {
  profile: {},
  client: {},
  user_ids: [],
  users: {},
  client_ids: [],
  clients: {},
  users_loaded: false,
  clients_loaded: false,
};

export const {Provider, Context} = createDataContext(
  authReducer,
  {
    getProfile,
    setUserDoc,
    getUsers,
    getClients,
    updateClient,
    deleteClient,
    deleteUser,
  },
  defaultValues,
);
