import {
  Checkbox,
  FormControl,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import React, {useState} from 'react';
import {useAuth} from '../../hooks/use-auth';
import {Modal} from './modal';

export const NewClientModal = ({active, setActive}) => {
  const {updateClient} = useAuth();

  const [form, setForm] = useState({customerID: '', plaid: true, bill: true});

  return (
    <Modal active={active} setActive={setActive}>
      <div className="box flex-column">
        <Typography sx={{textAlign: 'center', fontSize: '22px'}}>
          New Client
        </Typography>
        <Grid container spacing={0}>
          <Grid
            item
            xs={4}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}>
            <Typography>Customer ID:</Typography>
          </Grid>
          <Grid item xs={8}>
            <FormControl fullWidth>
              <TextField
                id="customerID"
                label="Customer ID"
                placeholder="Customer ID"
                value={form.customerID}
                type="text"
                onChange={evt =>
                  setForm({...form, customerID: evt.target.value})
                }
                variant="filled"
              />
            </FormControl>
          </Grid>
        </Grid>

        <br />
        <Grid container spacing={0}>
          <Grid
            item
            xs={4}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}>
            <Typography>Bank Accounts:</Typography>
          </Grid>
          <Grid item xs={8}>
            <Checkbox
              checked={form.plaid}
              onChange={evt => setForm({...form, plaid: evt.target.checked})}
            />
          </Grid>
        </Grid>
        <br />
        <Grid container spacing={0}>
          <Grid
            item
            xs={4}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}>
            <Typography>Bill.com Accounts:</Typography>
          </Grid>
          <Grid item xs={8}>
            <Checkbox
              checked={form.bill}
              onChange={evt => setForm({...form, bill: evt.target.checked})}
            />
          </Grid>
        </Grid>
        <br />
        <div className="flex-row justify-end">
          <button
            onClick={async () => {
              const {customerID, bill, plaid} = form;

              await updateClient(customerID, {bill, plaid});
              setActive(false);
            }}>
            Add Client
          </button>
        </div>
      </div>
    </Modal>
  );
};
