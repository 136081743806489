import React, { useEffect, useRef } from 'react';
import './styles.css';

export const Modal = ({ active, setActive, children, disabled_dismiss }) => {
  // AUTO CLOSE MODAL
  const modalRef = useRef(null); 

  useEffect(() => {
    function handleClickOutside(event) {
      if (disabled_dismiss) {
        return;
      }
      if (modalRef.current && event.target === modalRef.current) {
        setActive(false);
      }
    }

    window.addEventListener('click', handleClickOutside);

    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, [setActive, disabled_dismiss, modalRef]);

  return (
    <div
      ref={modalRef}
      className={`modal ${active ? 'show-modal' : ''}`}
    >
      <div className="modal-content">
        {children}
      </div>
    </div>
  );
};
