import React, { useState } from 'react';
import {
  Box,
  Container,
  FormControl,
  OutlinedInput,
  InputLabel,
  InputAdornment,
  IconButton,
  Button,
  Typography,
  Alert,
  Collapse,
  Link,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/use-auth';

function Login() {
  const navigate = useNavigate();
  const { getProfile } = useAuth();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const [processingLogin, setProcessingLogin] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const processLogin = async (event) => {
    event.preventDefault();
    const auth = getAuth();

    setProcessingLogin(true);

    signInWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        const {
          user: { uid },
        } = userCredential;
        const profile = await getProfile(uid);
        if (!!profile.admin) {
          navigate('/admin/user/dashboard');
        } else {
          navigate('/dashboard');
        }
        setProcessingLogin(false);
      })
      .catch((error) => {
        setError(`Error code ${error.code}: ${error.message}`);
        setProcessingLogin(false);
      });
  };

  return (
    <Container maxWidth="sm" style={{ marginTop: '50px' }}>
      <Box
        sx={{
          backgroundColor: 'white',
          borderRadius: '10px',
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
        }}
      >
        {/* Top Bar */}
        <Box
          sx={{
            backgroundColor: '#f6f6f6',
            padding: '10px',
            borderTopLeftRadius: '10px',
            borderTopRightRadius: '10px',
            color: '#fff',
            textAlign: 'center',
          }}
        >
          <Typography style={{
            fontSize: '30px',
            fontWeight: '500',}}>
              LOGIN
          </Typography>
        </Box>

        {/* Form Section */}
        <Box sx={{ padding: '30px' }}>
          <form onSubmit={processLogin}>
            <FormControl fullWidth margin="normal">
              <InputLabel 
                htmlFor="email" 
                sx={{ color: 'black' }}
              >
                Username:
              </InputLabel>
              <OutlinedInput
                id="email"
                value={email}
                onChange={(evt) => setEmail(evt.target.value)}
                label="Username:"
                inputProps={{ style: { color: 'black' } }} // Set text color to black
                style={{
                  backgroundColor: '#fff',
                  borderRadius: '.375rem',
                }}
                sx={{
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'black',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'black',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'black',
                  },
                  '& .MuiInputAdornment-root .MuiSvgIcon-root': {
                    color: 'black', // Icon color
                  },
                }}
                fullWidth
              />
            </FormControl>
            <FormControl fullWidth margin="normal">
              <InputLabel 
                htmlFor="password" 
                sx={{ color: 'black' }}
              >
                Password:
              </InputLabel>
              <OutlinedInput
                id="password"
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={(evt) => setPassword(evt.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    {/* <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      sx={{ color: 'black' }} // Icon color
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton> */}
                  </InputAdornment>
                }
                label="Password:"
                inputProps={{ style: { color: 'black' } }} // Set text color to black
                style={{
                  backgroundColor: '#fff',
                  borderRadius: '.375rem',
                }}
                sx={{
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'black',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'black',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'black',
                  },
                  '& .MuiInputAdornment-root .MuiSvgIcon-root': {
                    color: 'black', // Icon color
                  },
                }}
                fullWidth
              />
            </FormControl>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '20px',
              }}
            >
              <Button
                type="submit"
                disabled={processingLogin}
                sx={{
                  color: 'white',
                  backgroundColor: '#0d6efd',
                  '&:hover': {
                    backgroundColor: '#0b5ed7', // Change hover color
                  },
                }}
                style={{
                  padding: '10px',
                  borderRadius: '.375rem',
                }}
              >
                {processingLogin ? 'Logging in...' : 'Sign In'}
              </Button>
            </Box>
          </form>
          {error && (
            <Collapse in={error !== ''} style={{ marginTop: '15px' }}>
              <Alert severity="error">{error}</Alert>
            </Collapse>
          )}
          <Typography variant="body2" align="center" style={{ marginTop: '20px' }}>
            Not yet registered?{' '}
            <Link href="/signup" style={{ color: '#0d6efd' }}>
              Sign up
            </Link>
          </Typography>
        </Box>
      </Box>
    </Container>
  );
}

export default Login;
