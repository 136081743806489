import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {CheckBox} from '../../components/Inputs';
import {PlaidUpdater} from '../../components/Plaid/PlaidLinker';
import {usePlaid} from '../../hooks/use-plaid';
import '../../styles/plaid.css';

export default function AccountPage({user}) {
  const navigate = useNavigate();
  const {item_id} = useParams();
  const {
    state: {accounts, accountsMissingInfo, institutions},
    addMissingInfo,
    setLinking,
    removeItem,
  } = usePlaid();

  const [active_accounts, setAccounts] = useState([]);
  const [active_ins, setInst] = useState(null);

  useEffect(() => {
    const filtered = accounts.filter(acc => acc.item_id === item_id);
    const current = institutions.find(ins => ins.item_id === item_id);

    setAccounts(filtered);
    setInst(current);
  }, [accounts, institutions, item_id]);

  return (
    <div className="page-container">
      <div className="flex-row justify-between">
        <table>
          <thead>
            <tr>
              <th>Bank</th>
              <th>Type</th>
              <th>Mask</th>
              <th>Status</th>
              <th>Ignore</th>
            </tr>
          </thead>
          <tbody>
            {active_accounts.length > 0 &&
              active_accounts.map(item => {
                const {name, subtype, mask, disable, error, ignore} = item;
                const truncatedName =
                  name.length > 20 ? `${name.substring(0, 20)}...` : name;

                const status = !error ? 'Active' : 'Error';
                return disable === false || ignore !== undefined ? (
                  <tr key={mask}>
                    <td>{truncatedName}</td>
                    <td>{subtype}</td>
                    <td>{mask ? `#${mask}` : 'N/A'}</td>
                    <td>{status}</td>
                    <td>
                      <CheckBox
                        value={item.ignore}
                        setValue={value => {
                          item.ignore = value;
                          item.disable = value;
                          addMissingInfo(
                            user.uid,
                            item,
                            item.mask,
                            accountsMissingInfo,
                            accounts,
                          );
                        }}
                      />
                    </td>
                  </tr>
                ) : null;
              })}
          </tbody>
        </table>
        <div>
          {active_ins?.error ? (
            <PlaidUpdater
              uid={user.uid}
              item_id={active_ins?.item_id}
              access_token={active_ins?.access_token}>
              <div
                style={{
                  right: 30,
                  zIndex: 1,
                  backgroundColor: '#316b3b',
                  width: '150px',
                  height: '30px',
                  border: '0px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  margin: '0px 32px',
                }}>
                <p
                  style={{
                    color: 'white',
                    fontSize: 14,
                    padding: '0px',
                    alignSelf: 'center',
                  }}>
                  Update
                </p>
              </div>
            </PlaidUpdater>
          ) : (
            <button
              className="disconnect"
              onClick={() => {
                try {
                  setLinking(true);
                  removeItem(
                    user.uid,
                    active_ins?.access_token,
                    item_id,
                    accounts,
                  );
                  navigate('/dashboard');
                } catch (err) {
                  console.log(err);
                }
              }}>
              Disconnect
            </button>
          )}
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          margin: '32px',
        }}>
        <button
          className="disconnect"
          onClick={() => {
            navigate('/dashboard');
          }}>
          Done
        </button>
      </div>
    </div>
  );
}
