import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  where,
} from 'firebase/firestore';
import {httpsCallable} from 'firebase/functions';
import {db, functions} from './firebase';

const FirestoreManager = {
  getBillOrganizations: async ({userName, password}) => {
    try {
      const query = httpsCallable(functions, 'bill-getOrganizations');
      const orgs = await query({userName, password});

      return orgs.data;
    } catch (err) {
      console.log(err);
      return false;
    }
  },

  checkUserMapping: async documentID => {
    try {
      const checkID = httpsCallable(functions, 'auth-checkUserMapping');
      const idValid = await checkID({documentID});

      return idValid.data;
    } catch (err) {
      console.log(err);
      return false;
    }
  },

  addBillAccount: async (username, password, uid) => {
    try {
      const login = httpsCallable(functions, 'bill-login');
      const response = await login({username, password});
      console.log(response);

      const {response_status, response_message, response_data} = response.data;
      console.log(response_status);
      if (response_status === 0) {
        console.log(uid);
        const userRef = doc(db, 'users', uid);
        await setDoc(
          userRef,
          {bill: {username: username, password: password}},
          {merge: true},
        );
        return true;
      } else {
        window.alert(response_data.error_message);
        return false;
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  },

  getAccounts: async uid => {
    try {
      const accountQuery = query(collection(db, 'users', uid, 'accounts'));
      const accounts = getDocs(accountQuery).then(querySnapshot => {
        return querySnapshot.docs.map(doc => ({...doc.data(), id: doc.id}));
      });

      return accounts;
    } catch (err) {
      throw err;
    }
  },

  getAccountsByItem: async (uid, ins) => {
    try {
      const accountQuery = query(
        collection(db, 'users', uid, 'accounts'),
        where('item_id', '==', ins),
      );
      const accounts = getDocs(accountQuery).then(querySnapshot => {
        return querySnapshot.docs.map(doc => ({...doc.data(), id: doc.id}));
      });

      return accounts;
    } catch (err) {
      throw err;
    }
  },

  getInsByItemID: async item_id => {
    try {
      const ins = query(
        collection(db, 'plaid'),
        where('item_id', '==', item_id),
      );
      const instituions = getDocs(ins).then(querySnapshot => {
        return querySnapshot.docs.map(doc => ({...doc.data(), id: doc.id}));
      });
      return instituions;
    } catch (err) {
      console.log(err);
    }
  },

  getInstitutions: async uid => {
    try {
      const ins = query(collection(db, 'plaid'), where('uid', '==', uid));
      const instituions = getDocs(ins).then(querySnapshot => {
        return querySnapshot.docs.map(doc => ({...doc.data(), id: doc.id}));
      });
      return instituions;
    } catch (err) {
      throw err;
    }
  },

  addPlaidAccount: async (account, uid, item_id, access_token) => {
    try {
      account.item_id = item_id;
      account.access_token = access_token;
      if (account.ignore === undefined) {
        if (account.type === 'depository' || account.type === 'credit') {
          account.disable = false;
        } else {
          account.disable = true;
        }
      }
      setDoc(doc(db, 'users', uid, 'accounts', account.account_id), account);
      return;
    } catch (err) {
      console.log(err);
    }
  },

  getUserObject: async uid => {
    try {
      const userRef = doc(db, 'users', uid);
      const user = await getDoc(userRef);
      if (user.exists()) {
        return user.data();
      } else {
        // doc.data() will be undefined in this case
        return null;
      }
    } catch (err) {
      console.log(err);
    }
  },

  getAllUsers: async () => {
    try {
      const userRef = collection(db, 'users');

      const users = getDocs(userRef).then(querySnapshot => {
        return querySnapshot.docs.map(doc => ({...doc.data(), id: doc.id}));
      });
      return users;
    } catch (err) {
      console.log(err);
    }
  },

  setUserObject: async (uid, obj) => {
    try {
      await setDoc(doc(db, 'users', uid), obj, {merge: true});
    } catch (err) {
      console.log(err);
    }
  },

  removeUserObject: async uid => {
    try {
      await deleteDoc(doc(db, 'users', uid));
    } catch (err) {
      throw err;
    }
  },

  setPlaidItem: async (item_id, item) => {
    try {
      await setDoc(doc(db, 'plaid', item_id), item, {merge: true});
    } catch (err) {
      throw err;
    }
  },

  removePlaidItemsAndAccounts: async (uid, access_token) => {
    try {
      const ins = query(
        collection(db, 'plaid'),
        where('access_token', '==', access_token),
      );
      const insQuerySnapshot = await getDocs(ins);
      insQuerySnapshot.forEach(async document => {
        await deleteDoc(doc(db, 'plaid', document.id));
      });
      const acc = query(
        collection(db, 'users', uid, 'accounts'),
        where('access_token', '==', access_token),
      );
      const accQuerySnapshot = await getDocs(acc);
      accQuerySnapshot.forEach(async document => {
        await deleteDoc(doc(db, 'users', uid, 'accounts', document.id));
      });
    } catch (err) {}
  },

  removePlaidItems: async access_token => {
    try {
      const ins = query(
        collection(db, 'plaid'),
        where('access_token', '==', access_token),
      );
      const insQuerySnapshot = await getDocs(ins);
      insQuerySnapshot.forEach(async document => {
        await deleteDoc(doc(db, 'plaid', document.id));
      });
    } catch (err) {}
  },

  setBillItem: async (uid, item) => {
    try {
      await setDoc(doc(db, 'bill', uid), item, {merge: true});
    } catch (err) {
      throw err;
    }
  },

  removeBillItem: async uid => {
    try {
      await deleteDoc(doc(db, 'bill', uid));
    } catch (err) {
      throw err;
    }
  },

  getBillItems: async uid => {
    try {
      const q = query(collection(db, 'bill'), where('uid', '==', uid));
      const bills = getDocs(q).then(querySnapshot => {
        return querySnapshot.docs.map(doc => ({...doc.data(), id: doc.id}));
      });
      return bills;
    } catch (err) {
      throw err;
    }
  },

  getAllBillItems: async () => {
    try {
      const con = collection(db, 'bill');
      const connections = getDocs(con).then(querySnapshot => {
        return querySnapshot.docs.map(doc => ({...doc.data(), id: doc.id}));
      });
      return connections;
    } catch (err) {
      throw err;
    }
  },

  setClient: async (uid, item) => {
    try {
      await setDoc(doc(db, 'clients', uid), item, {merge: true});
    } catch (err) {
      throw err;
    }
  },

  removeClient: async uid => {
    try {
      await deleteDoc(doc(db, 'clients', uid));
    } catch (err) {
      throw err;
    }
  },

  getClients: async => {
    try {
      const q = query(collection(db, 'clients'));
      const clients = getDocs(q).then(querySnapshot => {
        return querySnapshot.docs.map(doc => {
          return {...doc.data(), id: doc.id};
        });
      });
      return clients;
    } catch (err) {
      throw err;
    }
  },

  getClient: async uid => {
    try {
      const clientRef = doc(db, 'clients', uid);
      const client = await getDoc(clientRef);
      if (client.exists()) {
        return client.data();
      } else {
        return null;
      }
    } catch (err) {
      console.log(err);
    }
  },

  setUserMapping: async (uid, item) => {
    try {
      await setDoc(doc(db, 'user-mappings', uid), item, {merge: true});
    } catch (err) {
      throw err;
    }
  },

  getUserMapping: async uid => {
    try {
      const clientRef = doc(db, 'user-mappings', uid);
      const client = await getDoc(clientRef);
      if (client.exists()) {
        return client.data();
      } else {
        return null;
      }
    } catch (err) {
      console.log(err);
    }
  },
};

export default FirestoreManager;
