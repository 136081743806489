import { getApp } from 'firebase/app';
import { getFunctions, httpsCallable } from 'firebase/functions';
import React, { useEffect, useState } from 'react';
import { PlaidLink } from 'react-plaid-link';
import PlaidManager from '../../api/plaidManager';
import { usePlaid } from '../../hooks/use-plaid';
const functions = getFunctions(getApp());
//connectFunctionsEmulator(functions, "localhost", 5001);

export const PlaidLinker = React.memo(
  ({ children, onSuccess, onExit, style, uid }) => {
    const {
      state: { accounts, institutions },
      addPlaidInstitution,
      setLinking,
      addPlaidAccounts,
    } = usePlaid();

    const [token, setToken] = useState(null);

    const getToken = async () => {
      try {
        const getToken = httpsCallable(functions, 'plaid-getLinkToken');
        const linkToken = await getToken(uid);

        if (linkToken) {
          setToken(linkToken.data);
        }
      } catch (err) {
        console.error(err);
        // TODO: Implement error handling
      }
    };

    useEffect(() => {
      getToken();
    }, []);

    const handleSuccess = async (public_token, metadata) => {
      setLinking(true);
      try {
        if (isDuplicateAccount(metadata)) {
          window.alert(
            "It looks like you have already linked that account! Try waiting if you don't see it yet or link a different institution."
          );
          setLinking(false);
          return;
        }
        const { access_token, item_id } =
          await PlaidManager.exchangePublicToken(public_token);
        const itemDetails = await PlaidManager.getInstitutionWithMetaData(
          metadata.institution.institution_id
        );
        const item = {
          uid,
          access_token,
          item_id,
          institution_id: metadata.institution.institution_id,
          ...itemDetails,
        };
        await addPlaidAccounts(access_token, uid, item_id);
        await addPlaidInstitution(item_id, item, uid);
        setLinking(false);
      } catch (err) {
        console.error(err);
        setLinking(false);
      }
    };

    const isDuplicateAccount = (metadata) => {
      const inst_id = metadata.institution.institution_id;
      const uniqueAccount =
        !institutions.some(
          (institution) => institution.institution_id === inst_id
        ) &&
        accounts.every((currentAccount) =>
          metadata.accounts.every(
            (newAccount) =>
              currentAccount.mask !== newAccount.mask ||
              currentAccount.name !== newAccount.name
          )
        );
      return !uniqueAccount;
    };

    return (
      <PlaidLink
        token={token}
        style={{ background: 'none', border: 'none', padding: 0 }}
        onExit={onExit}
        onSuccess={handleSuccess}
      >
        {children ? children : <h2>Connect a bank account</h2>}
      </PlaidLink>
    );
  }
);

export const PlaidUpdater = React.memo(
  ({ children, onSuccess, uid, onExit, access_token, item_id }) => {
    const { addPlaidInstitution } = usePlaid();

    const [token, setToken] = useState(null);

    const getToken = async () => {
      try {
        if (!uid) {
          return;
        }
        const getToken = httpsCallable(functions, 'plaid-getUpdateToken');
        const data = { uid, access_token };
        const linkToken = await getToken(data);

        if (linkToken) {
          setToken(linkToken.data);
        }
      } catch (err) {
        console.error(err);
        //TODO: Implement error handling
      }
    };

    useEffect(() => {
      getToken();
    }, []);

    const handleSuccess = async () => {
      try {
        window.alert(
          'Updating your account was successful. Your transactions and balances will start updating again.'
        );
        addPlaidInstitution(item_id, { error: null });
        onSuccess();
      } catch (err) {
        console.error(err);
        //TODO: Implement proper error handling
      }
    };

    return (
      <PlaidLink
        token={token}
        style={{ background: 'none', border: 'none', padding: 0 }}
        onExit={onExit}
        onSuccess={handleSuccess}
      >
        {children ? children : <h2>Update account</h2>}
      </PlaidLink>
    );
  }
);
