import { httpsCallable } from 'firebase/functions';
import { functions } from './firebase';

const PlaidManager = {
  getLinkToken: async (uid) => {
    try {
      const getToken = httpsCallable(functions, 'plaid-getLinkToken');
      const token = await getToken({ uid: uid });

      return token;
    } catch (err) {
      console.error('PLAID MANAGER', err);
      throw err;
    }
  },
  exchangePublicToken: async (token) => {
    try {
      const getToken = httpsCallable(functions, 'plaid-exchangeToken');
      const response = await getToken(token);

      return response.data;
    } catch (err) {
      console.error('PLAID MANAGER', err);
      throw err;
    }
  },
  addAccount: async (access_token) => {
    try {
      const addAccount = httpsCallable(functions, 'plaid-getAccounts');
      const response = await addAccount(access_token);

      return response;
    } catch (err) {
      console.error('PLAID MANAGER', err);
    }
  },
  getInstitutionWithMetaData: async (institution_id) => {
    try {
      const getIns = httpsCallable(functions, 'plaid-getInstitutionById');
      const response = await getIns(institution_id);

      return response.data.institution;
    } catch (err) {
      console.log(err);
    }
  },
  removeItem: async (access_token) => {
    try {
      const removeIns = httpsCallable(functions, 'plaid-removeItem');
      const response = await removeIns(access_token);

      return response.data;
    } catch (err) {
      console.log(err);
    }
  },
};
export default PlaidManager;
