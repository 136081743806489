import FirestoreManager from '../api/firebaseManager';
import createDataContext from './createDataContext';

const plaidReducer = (state, action) => {
  const {connection, id, connections, ids, uid, user_id} = action.payload;
  switch (action.type) {
    case 'default':
      return {...state, ...action.payload};
    case 'createConnection':
      return {
        ...state,
        connection_ids: [id, ...state.connection_ids],
        connections: {...state.connections, [id]: connection},
      };
    case 'getUserConnections':
      return {
        ...state,
        user_connections: {...state.user_connections, [uid]: ids},
        connections: {...state.connections, ...connections},
      };
    case 'removeUser':
      const filtered_connections = {...state.user_connections, [user_id]: []};
      return {
        ...state,
        user_connections: filtered_connections,
      };
    case 'error':
      return {...state, error: action.payload};
    default:
      return state;
  }
};

const fetchInitialBill = dispatch => async uid => {
  try {
    const connection_list = await FirestoreManager.getBillItems(uid);

    const connections = {};
    const connection_ids = connection_list.map(account => {
      const {id} = account;
      connections[id] = account;
      return id;
    });

    const payload = {
      connection_ids,
      connections,
      error: null,
    };
    dispatch({type: 'default', payload});
  } catch (err) {
    dispatch({type: 'error', payload: 'Something went wrong'});
  }
};

const createConnection = dispatch => async connection => {
  try {
    const {uid, userName} = connection;
    await FirestoreManager.setBillItem(userName, connection);

    const payload = {
      id: uid,
      connection,
      error: null,
    };
    dispatch({type: 'createConnection', payload});
    return {success: true, error: null};
  } catch (err) {
    console.log(err);
    dispatch({type: 'error', payload: 'Something went wrong'});
    return {success: false, error: err};
  }
};

const getUserConnections = dispatch => async uid => {
  try {
    const connection_list = await FirestoreManager.getBillItems(uid);

    const connections = {};
    const ids = connection_list.map(account => {
      const {id} = account;
      connections[id] = account;
      return id;
    });

    const payload = {ids, connections, uid};
    dispatch({type: 'getUserConnections', payload});
  } catch (err) {
    dispatch({type: 'error', payload: 'Something went wrong'});
  }
};

const removeUserConnections = dispatch => async (user_id, connection_ids) => {
  try {
    await Promise.all(
      connection_ids.map(async connection_id => {
        await FirestoreManager.removeBillItem(connection_id);
      }),
    );
    const payload = {user_id};
    dispatch({type: 'removeUser', payload});
  } catch (err) {
    dispatch({type: 'error', payload: 'Something went wrong'});
  }
};

const defaultValues = {
  connection_ids: [],
  connections: {},
  user_connections: [],
  error: null,
};

export const {Provider, Context} = createDataContext(
  plaidReducer,
  {
    fetchInitialBill,
    createConnection,
    getUserConnections,
    removeUserConnections,
  },
  defaultValues,
);
