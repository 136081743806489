import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
} from 'firebase/auth';
import { auth } from './firebase';

const signup = async (email, password) => {
  try {
    createUserWithEmailAndPassword(auth, email, password);
  } catch (err) {
    throw err;
  }
};

const signin = async (email, password) => {
  try {
    signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    throw err;
  }
};

const logout = async () => {
  try {
    await signOut(auth);
  } catch (err) {
    throw err;
  }
};

export { logout, signin, signup };
