import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Spinner from 'react-activity/dist/Spinner';
import 'react-activity/dist/Spinner.css';
import { useNavigate } from 'react-router-dom';
import { NewUserModal } from '../../components/modal/new-user-modal';
import { useAuth } from '../../hooks/use-auth';
import { useBill } from '../../hooks/use-bill';
import { usePlaid } from '../../hooks/use-plaid';
import '../../styles/plaid.css';

const UserDashboard = ({ user }) => {
  const navigate = useNavigate();
  const {
    state: { user_ids, users, users_loaded },
    getUsers,
  } = useAuth();

  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState(false);
  const [sorted, setSorted] = useState([]);

  const sort = field => {
    const s = [...user_ids].sort((a, b) => {
      const a_obj = users[a];
      const b_obj = users[b];
      // console.log(a_obj[field], b_obj[field]);
      if (
        typeof a_obj?.[field] === 'string' &&
        typeof b_obj?.[field] === 'string'
      ) {
        return a_obj[field].localeCompare(b_obj[field]);
      } else {
        return b_obj?.[field] - a_obj?.[field];
      }
    });
    setSorted(s);
  };

  useEffect(() => {
    const load = async () => {
      setLoading(true);
      await getUsers();
      setLoading(false);
    };
    if (!users_loaded) {
      load();
    }
  }, []);

  useEffect(() => {
    sort('customerID');
  }, [user_ids]);

  return (
    <div className="page-container">
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Spinner size={50} color={'gray'} speed={0.5} style={{ top: 300 }} />
        </div>
      ) : (
        <div>
          <div className="flex-row justify-between align-center">
            <Typography sx={{ fontSize: 24, fontWeight: 'bold' }}>
              Users
            </Typography>
            <button
              onClick={() => {
                setActive(!active);
              }}>
              Add User
            </button>
          </div>
          <table>
            <thead>
              <tr>
                <th
                  onClick={() => {
                    sort('customerID');
                  }}>
                  Customer Id
                </th>
                <th
                  onClick={() => {
                    sort('emailAddress');
                  }}>
                  User Email
                </th>
                <th
                  onClick={() => {
                    sort('fullName');
                  }}>
                  Name
                </th>
                <th>Bank Accounts</th>
                <th>Bill.com Accounts</th>
              </tr>
            </thead>
            <tbody>
              {sorted.map(id => {
                return <UserItem key={id} id={id} />;
              })}
            </tbody>
          </table>
        </div>
      )}
      <NewUserModal active={active} setActive={setActive} />
    </div>
  );
};

const UserItem = ({ id }) => {
  const navigate = useNavigate();

  const {
    state: { users },
  } = useAuth();
  const {
    state: { user_accounts },
    getUserAccounts,
  } = usePlaid();
  const {
    state: { user_connections, connections },
    getUserConnections,
  } = useBill();

  const { uid, customerID, emailAddress, fullName } = users?.[id] ?? {};

  const accounts = user_accounts?.[id] ?? [];
  const cons = user_connections?.[id] ?? [];

  let bill_orgs = 0;
  cons.forEach(id => {
    const link = connections[id];
    bill_orgs += link?.organizations?.length ?? 0;
  });

  useEffect(() => {
    // TODO: getBill.com Accounts
    if (user_accounts[id] === undefined) {
      getUserAccounts(id);
    }

    if (user_connections[id] === undefined) {
      getUserConnections(id);
    }
  }, []);

  return (
    <tr key={uid}>
      <td>{customerID}</td>
      <td>{emailAddress}</td>
      <td>{fullName}</td>
      <td
        style={{ color: "blue", fontWeight: "bold", cursor: "pointer" }}
        onClick={() => {
          navigate(`/admin/user/accounts/${uid}`);
        }}
      >
        {accounts?.length || 0} Accounts
      </td>
      <td
        style={{ color: "blue", fontWeight: "bold", cursor: "pointer" }}
        onClick={() => {
          navigate(`/admin/user/accounts/${uid}`);
        }}
      >
        {bill_orgs} Accounts
      </td>
    </tr>
  );
};

export default UserDashboard;
